import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Button from "components/shared/Button";
import cn from "classnames";

const RegisterMobileNav = ({ setActiveNavOverlay }) => {
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 925);

  useEffect(() => {
    const updateView = () => {
      const windowWidth = window.innerWidth;
      setIsMobileView(windowWidth <= 925);
    };

    updateView();
    window.addEventListener("resize", updateView);
    return () => {
      window.removeEventListener("resize", updateView);
    };
  }, []);

  return (
    <>
      <div
        className={cn(
          "fixed bottom-0 left-0 top-0 z-[101] h-screen w-full cursor-pointer bg-[#00000047] backdrop-sepia-[12px] lg:bg-transparent",
          isMobileView
            ? "opacity-1 pointer-events-auto"
            : "pointer-events-none opacity-0"
        )}
        onClick={() => setActiveNavOverlay(false)}
      >
        <div
          className={cn(
            "z-[150] ml-auto h-full w-full cursor-default bg-white pb-4 transition-all duration-200 ease-in sm:max-w-[360px] min-[926px]:hidden",
            isMobileView ? "left-0" : "-left-full"
          )}
          onClick={(e) => e.stopPropagation()}
        >
          <div className="max-w-full lg:max-w-5xl mx-auto xl:max-w-[1194px] px-4 flex w-full justify-end">
            <Button
              variant="outline"
              className="z-10 mt-4 rounded-md bg-transparent !p-[7px] bg-white border-1.5 border-[#D9DFE4] text-black hover:bg-[#F6F7F8]"
              onClick={() => {
                setActiveNavOverlay(false);
              }}
            >
              <img src="/icons/close-icon.svg" alt="Close menu" />
            </Button>
          </div>
          <div className="mx-6 mt-1 flex flex-col border-b border-[#F6F7F8] pb-6">
            <p className="text-2xl font-semibold leading-8 capitalize text-black">
              Sign in
            </p>
            <p className="text-sm font-normal leading-5 mt-1 text-neutral-800">
              Add your vehicle, get bids & finalize your deal.
            </p>
            <Link
              to="https://www.appautostoday.net/login"
              className="text-sm font-medium leading-5 mt-5 w-full rounded-md bg-[#ca3453] px-10 py-3 text-center text-white hover:bg-[#eb818f] lg:py-4"
            >
              Seller Login
            </Link>
          </div>
          <ul className="!mx-6 !my-2.5 flex flex-col" data-aos="fade-up">
            <li>
              <Link
                to="https://www.autostoday1.net/cars-for-sale/all-cars"
                className="text-base font-medium leading-6 -mx-1 inline-block px-1 py-4 text-neutral-800 hover:text-black"
              >
                Buy
              </Link>
            </li>
            <li>
              <Link
                to="https://www.appautostoday.net/register"
                className="text-base font-medium leading-6 -mx-1 inline-block px-1 py-4 text-black"
              >
                Sell/Trade
              </Link>
            </li>
            <li>
              <Link
                to="https://www.autostoday1.net/about-us"
                className="text-base font-medium leading-6 -mx-1 inline-block px-1 py-4 text-neutral-800 hover:text-black"
              >
                About Us
              </Link>
            </li>
            <li>
              <Link
                to="https://www.autostoday1.net/how-it-works"
                className="text-base font-medium leading-6 -mx-1 inline-block px-1 py-4 text-neutral-800 hover:text-black"
              >
                How It Works
              </Link>
            </li>
            <li>
              <Link
                to="https://www.autostoday1.net/dealers"
                className="text-base font-medium leading-6 -mx-1 inline-block px-1 py-4 text-neutral-800 hover:text-black"
              >
                Dealers
              </Link>
            </li>
          </ul>
          <div className="mx-6">
            <hr className="h-[1px] w-full border-0 bg-neutral-50" />
          </div>
          <ul className="!mx-6 !mt-2.5 inline-flex flex-col">
            <li>
              <Link
                to="https://www.autostoday1.net/saved-vehicles"
                className="text-base font-medium leading-6 -mx-1 inline-flex gap-2.5 items-center px-1 py-4 text-neutral-800 hover:text-black"
              >
                <span>Saved Vehicles</span>
                <span className="text-xs font-medium leading-4 inline-flex h-5 w-[37px] items-center justify-center rounded-[4px] bg-orange-500 text-white">
                  New
                </span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default RegisterMobileNav;
