import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import RegisterMobileNav from "./RegisterMobileNav";
import RegisterNavLinks from "./RegisterNavLinks";
import Button from "components/shared/Button";

import cn from "classnames";

export const navbarlinks = [
  {
    href: "https://www.autostoday1.net/cars-for-sale/all-cars",
    text: "Buy",
    isActive: false,
  },
  {
    href: "https://www.appautostoday.net/register",
    text: "Sell/Trade",
    isActive: true,
  },
  {
    href: "https://www.autostoday1.net/about-us",
    text: "About Us",
    isActive: false,
  },
  {
    href: "https://www.autostoday1.net/how-it-works",
    text: "How It Works",
    isActive: false,
  },
  {
    href: "https://www.autostoday1.net/dealers",
    text: "Dealers",
    isActive: false,
  },
];

const RegisterNavbar = () => {
  const [activeNavOverlay, setActiveNavOverlay] = useState(false);
  const [hasShadow, setHasShadow] = useState(false);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 925);

  useEffect(() => {
    if (activeNavOverlay && isMobileView) {
      document.body.classList.add("overflow-y-hidden");
      document.body.classList.remove("overflow-y-auto");
    } else {
      document.body.classList.add("overflow-y-auto");
      document.body.classList.remove("overflow-y-hidden");
    }
  }, [activeNavOverlay, isMobileView]);

  useEffect(() => {
    const updateView = () => {
      const windowWidth = window.innerWidth;
      setIsMobileView(windowWidth <= 925);
    };

    updateView();
    window.addEventListener("resize", updateView);
    return () => {
      window.removeEventListener("resize", updateView);
    };
  }, []);

  const handleScroll = () => {
    const offset = window.scrollY;
    setHasShadow(offset > 0);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      {isMobileView && activeNavOverlay && (
        <RegisterMobileNav setActiveNavOverlay={setActiveNavOverlay} />
      )}
      <nav
        className={cn(
          "duration-400 top-0 z-[100] h-[58px] sm:h-15 fixed w-full border border-solid border-[#DFEBF7] bg-white transition-all ease-in-out sm:border-none",
          { "shadow-light": hasShadow }
        )}
      >
        <div className="max-w-full lg:max-w-5xl mx-auto xl:max-w-[1194px] px-4 h-full">
          <div className="navbar_bg relative z-[101] flex h-full items-center justify-between rounded-[16px] sm:z-[50]">
            <div className="flex h-full flex-1 items-center justify-between">
              <div className="mr-4 flex h-full flex-1 items-center xl:mr-16">
                <div className="flex h-full flex-shrink-0 items-center md:w-[244px]">
                  <Link
                    to="https://www.autostoday1.net"
                    className="absolute top-0 flex h-full items-center"
                  >
                    <img
                      src="/images/logo-hq.png"
                      className="w-[156px]"
                      alt="logo"
                    />
                  </Link>
                </div>
                <div className={cn(isMobileView ? "hidden" : "block h-full")}>
                  <RegisterNavLinks list={navbarlinks} />
                </div>
              </div>
              <div
                className={cn(
                  isMobileView ? "hidden" : "flex items-center gap-5"
                )}
              >
                <Link
                  to="https://www.autostoday1.net/saved-vehicles"
                  className="group/main !rounded-md !p-3 hover:bg-[#FDF3F3]"
                  aria-label="Go to saved vehicles page"
                >
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    xmlns="http://www.w3.org/2000/svg"
                    className="fill-none"
                  >
                    <path
                      d="M15.6306 3.45753C15.2475 3.07428 14.7927 2.77026 14.2921 2.56284C13.7915 2.35542 13.2549 2.24866 12.7131 2.24866C12.1712 2.24866 11.6347 2.35542 11.1341 2.56284C10.6335 2.77026 10.1786 3.07428 9.79558 3.45753L9.00058 4.25253L8.20558 3.45753C7.43181 2.68376 6.38235 2.24906 5.28808 2.24906C4.1938 2.24906 3.14435 2.68376 2.37058 3.45753C1.59681 4.2313 1.16211 5.28075 1.16211 6.37503C1.16211 7.4693 1.59681 8.51876 2.37058 9.29253L3.16558 10.0875L9.00058 15.9225L14.8356 10.0875L15.6306 9.29253C16.0138 8.90946 16.3178 8.45464 16.5253 7.95404C16.7327 7.45345 16.8394 6.91689 16.8394 6.37503C16.8394 5.83316 16.7327 5.2966 16.5253 4.79601C16.3178 4.29542 16.0138 3.84059 15.6306 3.45753Z"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      className="stroke-[#76859A] group-hover/main:stroke-[#DF546A]"
                    />
                  </svg>
                </Link>
                <div className="group/main inline-block relative after:content-[''] after:w-full after:h-2 after:absolute after:-bottom-2 after:left-0">
                  <Button
                    variant="filled"
                    className="!bg-[#CA3453] text-white !hover:bg-[#DF546A] text-sm font-medium py-2.5 px-5 rounded-md"
                  >
                    Login
                  </Button>
                  <div className="top-menu absolute hidden group-hover/main:block z-10 bg-white rounded-lg border-t-2 border-secondary shadow w-full mt-2 right-0 left-auto">
                    <ul className="bg-white py-2 text-xl rounded-lg overflow-clip">
                      <li className="text-center cursor-pointer py-5 flex items-center justify-center hover:bg-neutral-light">
                        <Link
                          className="text-xs font-medium text-secondary"
                          to="https://www.appautostoday.net/login"
                        >
                          Seller Login
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <button
                onClick={() => setActiveNavOverlay(true)}
                type="button"
                className={cn(
                  isMobileView
                    ? "ml-5 inline-flex w-7 items-center justify-center rounded-md bg-transparent !px-0 hover:shadow-none"
                    : "hidden"
                )}
                aria-controls="mobile-menu"
                aria-expanded="false"
              >
                <img src="/icons/toggle-icon.svg" alt="Toggle menu" />
              </button>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default RegisterNavbar;
