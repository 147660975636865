import React, { useCallback, useEffect } from "react";
import Button from "./shared/Button";
import { UserDataContext } from "../App";
import { baseURL } from "config";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Modal from "./shared/Modal";

const LogoutConfirm = ({ setShowLogoutConfirm, showLogoutConfirm }) => {
  const [userData, setUserData] = React.useContext(UserDataContext);
  const navigate = useNavigate();

  const logout = useCallback(() => {
    setUserData({});
    setShowLogoutConfirm(false);
    window.location.href = "https://www.autostoday1.net/";
  }, [setShowLogoutConfirm, setUserData]);

  useEffect(() => {
    if (userData.email !== "") {
      logout();
    }
  }, [logout, userData.email]);

  function unListCar(callback) {
    if (userData.email !== "") {
      logout();
      return;
    }
    axios({
      method: "POST",
      url: baseURL + "/seller/unlist",
      headers: {
        Authorization: userData.token,
      },
    })
      .then((request) => {
        if (callback) {
          callback();
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  return (
    <Modal
      show={showLogoutConfirm}
      onHide={() => {
        setShowLogoutConfirm(false);
      }}
    >
      <Modal.Header>
        <Modal.Title style={{ color: "red" }}>IMPORTANT!</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        This action will delete all information of your vehicle from this
        platform. All photos, bids and messages will no longer be seen by you or
        any bidders.
        <br />
        <br />
        Setting up a profile before you sign off, will allow you to come back to
        your auction and information at any time.
      </Modal.Body>
      <Modal.Footer className="justify-between">
        <Button
          label="Set up profile"
          onClick={() => {
            setShowLogoutConfirm(false);
            navigate("/profile");
          }}
        />

        <Button
          label="Yes, Sign off"
          className="mr-2"
          variant="secondary"
          onClick={() => {
            unListCar(() => {
              logout();
            });
          }}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default LogoutConfirm;
